@import "~@blueprintjs/core/lib/scss/variables.scss";

.transparent {
  background-color: transparent;
}

.sidebar {
  background-color: $light-gray3;
  border-right: 1px solid $gray4;
  height: 100%;
  width: 200px;
  overflow-x: hidden;
}