@import "~@blueprintjs/core/lib/scss/variables.scss";

$gargoRed: #98040C;


label {
  min-width: 100px !important;
}

.bp3-control.bp3-checkbox.checkbox-no-label {
  width: unset !important;
  min-width: unset !important;
  margin-top: 8px;
  margin-right: 4px;
}

table.table-wide tbody tr.order-row-warning td {
  background-color: #FFE4A0 !important;
}

.bp3-menu label.bp3-control.bp3-checkbox {
   padding: 0;
   margin: 0;
}

.bp3-menu {
  .bp3-menu-item-label {
    display: flex;
    align-items: flex-end;

    .bp3-control.bp3-checkbox {
      width: 16px;
      min-width: 16px !important;
      max-width: 16px;
    }
    .bp3-control input ~ .bp3-control-indicator {
      margin-left: 4px;
    }
  }
}



.gargo {

  .navbar {
    background-color: #756A9E;
    color: white;

    .department {
      margin-top: 0px;
      margin-left: 20px;
    }
  }

  .table-wide {
    width: 100%;
    flex: 1;
    border-bottom: 1px solid $light-gray4;

    thead {
      tr {
        th {
          background-color: $light-gray4;
          text-transform: uppercase;
          &.align-right {
            text-align: right;
          }
        }
      }
    }

    tbody {
      tr.order-row-warning {
        td {
          background-color: "#FFE4A0" !important;
        }
      }
      th, td {
        border-bottom: 1px solid $light-gray4;

        &.align-right {
          text-align: right;
        }

        &.cell-payment-expense {
          color: $red3;
          font-weight: bold;
        }

        &.cell-payment-receive {
          color: $green2;
          font-weight: bold;
        }
      }
      tr.realization-row {
        td {
          background-color: $light-gray5 !important;
        }
      }
    }

    tfoot {
      tr {
        th {
          background-color: $light-gray4;
          border-top: 1px solid $gray5;
          &.align-right {
            text-align: right;
          }
          &.padded {
            padding-top: 30px;
          }
        }
      }
    }

    th, td {
      vertical-align: middle;
    }

  }

  th.flex-align-right, td.flex-align-right {
    text-align: right;
  }

  table.bp3-html-table.bp3-interactive tbody td.summary-cell {
    background-color: $light-gray4;
  }

  .customer-table {
    flex: 1;
    overflow-y: scroll;
  }

  .customer-detail {
    flex: 1;
    overflow-y: scroll;
    border-top: 1px solid $gray4;
    padding: 12px;
    background-color: $light-gray4;
  }

  .content-manager {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    height: calc(100vh - 50px);
    overflow-y: hidden;

    .manager-page {
      flex-direction: column;
      flex: 1;
      height: calc(100vh - 50px);
      overflow-y: scroll;
    }
  }

  .content {
    background-color: $light-gray4;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: column;

    .container {
      flex: 1;
      flex-direction: row;
      align-items: center;
      justify-items: center;
      display: flex;


      .bp3-control.bp3-checkbox.checkbox-no-label {
        width: unset !important;
        min-width: unset !important;
        margin-top: 8px;
        margin-right: 4px;
      }

      label {
        min-width: 100px;
      }

      .container-center {
        flex: 1;

        .panel-card {
          background-color: $light-gray5;

          .panel-half {
            width: 340px;
          }
        }
      }
    }
  }

  input[disabled] {
    color: black;
  }
}

.map-container {
  border: 1px solid $gray5;
}

.bp3-input:disabled, .bp3-input.bp3-disabled {
  color: black !important;
}

.row.control-panel {
  border-bottom: 1px solid $gray5;
  padding-top: 0px;
  margin-bottom: 20px;
}

.realization-title {
  font-size: 16px;
  text-transform: uppercase;
}


.logo-gargo-top {
  height: 70px;
  margin-top: 0px;
}

.input-euro-symbol {
  padding: 6px 12px;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: flex-start;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.center {
  justify-content: center;
}

.m-10 {
  margin: 10px;
}

.m-t-2 {
  margin-top: 2px;
}

.m-t-4 {
  margin-top: 4px;
}
.m-t-10 {
  margin-top: 14px;
}

.m-t-15 {
  margin-top: 15px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-r-30 {
  margin-right: 30px;
}

.m-r-40 {
  margin-right: 40px;
}

.m-r-50 {
  margin-right: 50px;
}

.p-r-20 {
  padding-right: 20px;
}

.m-r-60 {
  margin-right: 60px;
}

.w-30 {
  width: 30px;
}

.w-50 {
  width: 50px;
}

.w-70 {
  width: 70px;
}

.w-90 {
  width: 90px;
}

.w-100p {
  width: 100%;
}

.w-100p-20px {
  width: calc(100% - 20px);
}

.w-130 {
  width: 130px;
  max-width: 130px;
  min-width: 130px;
}

.w-150 {
  width: 150px;
}

.w-200 {
  width: 200px;
}

.w-240 {
  width: 240px;
}

.w-260 {
  width: 260px;

  .bp3-popover-target {
    width: 100%;
  }
}

.w-300 {
  width: 300px;

  .bp3-popover-target {
    width: 100%;
  }
}

.align-right {
  text-align: right;

  input {
    text-align: right;
  }
}

.text-input-plain.align-right input {
  text-align: right;
}

.date-range-input .bp3-input-group .bp3-input {
  width: 94px;
}

.bp3-control-group .bp3-input.text-area-plain-n-w:disabled {
  background-color: $light-gray2;
}

.bp3-control-group .bp3-input.text-area-plain-n-w {
  width: 100%;
  max-width: 100%;
  background-color: $light-gray5;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid $light-gray1;
  box-shadow: none;
}

.text-input-plain-n-w, .text-area-plain-n-w {
  border-left: none;
  border-right: none;
  box-shadow: none;
  width: 100%;
  max-width: 100%;

  input, textarea {
    width: 100%;
    max-width: 100%;
    background-color: $light-gray5;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid $light-gray1;
    box-shadow: none;
  }

  input:disabled, textarea:disabled {
    background-color: $light-gray5 !important;
  }
}

.text-input-plain {
  border-left: none;
  border-right: none;
  box-shadow: none;

  input {
    background-color: $light-gray5;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid $light-gray1;
    box-shadow: none;
  }
}

.text-input-short {
  border-left: none;
  border-right: none;
  box-shadow: none;
  max-width: 100px;

  input {
    max-width: 100px;
    background-color: white;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid $light-gray1;
    box-shadow: none;
  }
}

.white {
  color: white;

  span {
    color: white;
  }

  .bp3-icon {
    color: white;
    fill: white !important;
  }

  .bp3-icon > svg:not([fill]) {
    color: white;
  }
}

h1.bp3-heading.heading {
  background-color: $light-gray4;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  margin-bottom: 0;
  border-bottom: 1px solid $gray4;
  font-size: 18px;
}

.column-wide {
  flex-direction: column;
  display: flex;
  height: calc(100vh - 100px);
  width: 100%;
}

.row-selected td {
  background-color: $light-gray4;
}

.p-5 {
  padding: 5px;
}

td.link {
  color: #08c;
}

.actions {
  text-align: right;
  flex: 1;
  justify-items: flex-end;
  align-content: flex-end;
  align-items: flex-end;
  margin-right: 20px;
  padding-top: 6px;
}

.edit-dialog {
  padding: 16px 16px 0px 16px;
}

.center-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  height: 100vh;
}

.center-row {
  flex: 1;
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: row;
}

.bp3-card.login-box {
  width: 380px;
  background-color: $light-gray4;
  color: white;

  .header {
    width: 100%;
    height: 50px;
    text-align: center;
    padding-top: 8px;
  }

  .login-content {
    padding: 16px;
    color: black;
  }

  .login-error {
    color: $gargoRed;
  }
}

.login {
  background-color: $light-gray4;
}

.expense-row {
  .expense-title {
    width: 130px;
  }
  .expense-value {
    text-align: right;
    width: 70px;
  }
}

.text-gray {
  color: $gray1;
}

.report-card {
  margin: 20px;
}

.report-header {
  padding: 20px;
  font-weight: 500;
}

.realization-note {
  color: $gray2;
}

.field-label {
  font-size: 14px;
  font-weight: bold;
}

.filter-decor {
  margin-right: 8px;
  color: $light-gray1;
}

.money-input {
  width: 100px;

  input.bp3-input {
    width: 100px;
  }
}

.heading-label {
  line-height: 40px;
  font-size: 14px;
  padding: 0;
  margin: 0;
  text-align: right;
}

.highlight-1 {
  background-color: $lime5;
}

.highlight-2 {
  background-color: $turquoise5;
}

.progress-bar {
  border-radius: 0;
  .bp3-progress-bar {
    border-radius: 0;
    .bp3-progress-meter {
      border-radius:0
    }
  }
}

#order-detail-view {
  padding: 0 16px;
}

h5.hero-header {
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
}

h1.hero-header {
  font-size: 48px;
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 0;
}